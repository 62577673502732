@tailwind base;
@tailwind components;
@tailwind utilities;


body, #root {
    width: 100vw;
    min-height: 100vh;
    height: 100vh;
    /*overflow: hidden;*/
}


.fadein-animate {
    animation: fadein 0.3s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.word-spacing-wide {
    word-spacing: 10px;
}

.back-image {
    background: url("images/image.jpg") no-repeat;
    background-size: cover;
}

.diems-image {
    background: url("images/diems.jpg") no-repeat;
    background-size: cover;
}

.back-grad {
    /*background: linear-gradient(90deg, rgba(0, 0, 0, 0.81), rgba(208, 118, 1, 0.55));*/
    /*backdrop-filter: contrast(0.6);*/
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.81), #ffffff00)
}

.full-grad {
    background: radial-gradient(#ffffff00, #ffffff00, rgba(0, 0, 0, 0.81))
}


* {
    -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar-track, *::-webkit-scrollbar {
    display: none;
}

span {
    transition: all 0.5s ease;
}

.dot {
    content: " ";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 32px;
    background: black;
}
.line {
    content: " ";
    display: inline-block;
    width: 20px;
    height: 5px;
    border-radius: 32px;
    background: black;
}

.after-dot:after {
    content: " ";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 32px;
    background: black;
    position: absolute;
    margin-top: 36px;
}
input[type="range"]::-webkit-slider-runnable-track {
    height: 4px;
    cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
    position: relative;
    cursor: pointer;
    margin: -7px 0 0 0;
}


#noti-stack {
    margin: 2.5rem;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-transform: capitalize;
}
.notification {
    padding: 16px 24px;
    border-radius: 8px;
    font-size: 14px;
    width: fit-content;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(30, 144, 255, 0.9);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 0.25);
    margin-top: 20px;
}

.ani-nots {
    opacity: 0;
    animation: nots 5s;
}
@keyframes nots {
    0% {
        margin-bottom: -20px;
        opacity: 0;
    }
    10% {
        margin-bottom: 0;
        opacity: 1;
    }
    90% {
        margin-bottom: 0;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.success {
    background-color: mediumseagreen;
}

.info {
    background-color: dodgerblue;
}
.failed {
    background-color: lightcoral;
}

.scanner div {
    border: none!important;
    box-shadow: none!important;
    background: url("images/qr.png") no-repeat center;
    background-size: 200px;
    opacity: 0.8;
}

.form-element {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}
.form-element label {
    font-weight: bold;
    opacity: 0.6;
    margin-bottom: 4px;
}
.form-element input {
    outline: none;
    padding: 10px 16px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
}
.form-element input::placeholder {
    font-size: 14px;
}

